@use "../config/"as *;

.video-detail {
    // @extend %c-blue-bg-10;
    @extend %c-blue-3-bg-10;
    &>div {
        @extend %p-zero;
    }
    .embed-responsive.multi-view::before {
        padding-top: 0;
    }
}
.embed-responsive {
    video,iframe,.video-js{
        @include position-combo(inset);
    }
}

.waf-detail {
    @extend %c-white-10;
    @extend %p-zero;
    @extend %m-t-zero;
    &.related-video-section-missing{
        .video-section{
            flex-basis: 100%;
        }
    }

    .title {
        @extend %font-20-pb;
        @extend %c-white-10;
    }

    .content-section {
        padding: var(--two-space) var(--full-space);
        @extend %flex-column;
    }

    .article {
        &-description {
            order: 1;

            p {
                margin-block: var(--full-space) var(--quarter-space);
                @extend %font-14-pr;
                @extend %c-white-8;
            }
        }

        &-meta {
            align-items: baseline;
            @extend %flex;
            @extend %font-12-pr;

        }

        &-title {
            @extend %m-zero;
            @extend %c-white-10;
            @include truncate-vertical(1, 16, null, $font-pr);
        }

        &-list {
            @include listing-card(card);
        }

        &-content {
            @extend %p-x-full;
            @extend %m-t-full;
        }
    }

    .meta {
        @extend %font-12-pr;
        &-label{
            @extend %c-orange-10;
        }
    }

    .meta-wrap {
        order: 2;
    }

    .meta-date {
        order: 1;
        @extend %p-l-quarter;
        @extend %c-white-8;

        &:before {
            content: '|';
        }
    }
    .reaction-section {
        @extend %relative;
        .social-share-wrap {
            left: 0;
            right: unset;
        }
    }
    .icon-b-share {
        @extend %font-16-pr;
        @extend %c-white-8;
        @extend %flex-n-c;
        @extend %gap-half;
    }
    .toggle {
        &-checkbox {
            position: absolute;
            opacity: 0;
            width: 5rem;
            height: 3rem;
            cursor: pointer;
            z-index: var(--zindex-input);

            &:checked {
                +.toggle-btn {
                    @extend %c-orange-bg-10;

                    &:before {
                        @include transition(0.2s linear);
                        @include transform(translateX(2.1rem) translateY(-50%));
                    }
                }
            }
        }

        &-btn {
            position: relative;
            width: 5rem;
            height: 2.8rem;
            cursor: pointer;
            @include transition(background-color .4s linear);
            @include border-radius(10vmax);
            @extend %d-block;
            @extend %c-white-bg-5;

            &:before {
                content: '';
                width: 2.2rem;
                height: 2.2rem;
                @extend %d-block;
                @extend %c-white-bg-10;
                @extend %ratio-1-1;
                @extend %circle-radius;
                @include transition(0.2s linear);
                @include position-combo(y-center, null, 0.4rem);
            }
        }

        &-text {
            &:before {
                content: 'Always hide result';
                @extend %font-14-pr;
                @extend %c-blue-10;
            }
        }
    }

    .head {
        @extend %p-x-full;
    }

    .auto-play-section {
        display: none;
        @extend %flex-sb-c;
    }

    .img-box {
        border-radius: unset;
    }

    .item-type-video {
        .item-type-icon {
            @include position(var(--half-space), var(--half-space));

            &:after {
                @include icon("\e814");
                @include font(25);
                @include transition(0.3s ease-in);
                @extend %c-white-9;
                @extend %d-block;
            }

            &:before {
                content: "Watch";
                @include position(var(--half-space), var(--half-space));
                @extend %d-none;
                @extend %c-white-10;
                @extend %font-14-pb;
            }
        }
    }
    .share-label{
        @extend %d-none;
    }
    .video-section {
        .article-error {
            z-index: var(--zindex1);
            @extend %flex-column-c-c;
            @extend %p-full;
            @extend %h-100;
            @extend %w-100;
            @extend %position-t-l;
            @extend %c-blue-bg-10;
            @extend %text-center;
            @extend %gap-half;
            .article-title {
                height: auto;
                @extend %m-y-zero;
                @extend %flex-c-n;
            }
        }
    }
}

.waf-videodetail  {
    .article {
        &-meta {
            @extend %m-b-one-n-half;
        }
    }
    .sub-title {
        @extend %capitalize;
        @extend %c-orange-10;
        @extend %font-14-pb;
        @extend %m-b-full;
    }
    .video-section {
        .content-section {
            padding-top: calc(var(--full-space)*5);
            @extend %relative;
        }
        .title {
            @extend %m-b-half;
            @include font(20,24);
        }
        .match {
            &-info {
                @extend %flex-n-c;
                @extend %gap-full;
                @include position(var(--two-space),null,null,var(--full-space));
            }
            &-status {
                height: 3.2rem;
                @extend %relative;
                @extend %flex-n-c;
                @extend %c-white-10;
                @extend %capitalize;
                @extend %font-16-pr;
                @extend %full-radius;
                @extend %c-white-bg-2;
                @extend %p-l-one-n-half;
                @extend %p-r-full;
                @include border(1, c-white, 3);
                &:before {
                    content: '';
                    position: absolute;
                    left: var(--half-radius);
                    width: 1.5rem;
                    height: 1.5rem;
                    background-image: url('/static-assets/images/svg/live-red.svg?v=#{$image-version}');
                }
            }
            &-number {
                @extend %uppercase;
                @extend %c-white-10;
                @extend %font-24-pb;
            }
        }
    }
    .related-video-section {
        .head {
            @extend %m-b-full;
            .title {
                @extend %font-16-pr;
            }
        }
        .body {
            @extend %p-x-full;
        }
        .item-type-icon {
            right: unset;
            @include position-combo('center');
        }
        .article {
            &-list {
                flex-wrap: wrap;
                @extend %gap-half;
            }
            &-item {
                width: calc(50% - var(--quarter-space));
                flex-basis: calc(50% - var(--quarter-space));
                @extend %relative;
            }
            &-content {
                @extend %m-t-zero;
                @extend %p-x-half;
                @include position(null,null,var(--half-space),0);
            }
            &-title {
                display: none;
            }
            &-thumbnail {
                .img-box {
                    @include border-radius(var(--half-radius),hidden);
                    img {
                        object-fit: cover;
                    }
                }
            }
        }
        .match {
            &-number {
                @extend %font-12-pb;
                @extend %c-white-10;
                @extend %uppercase;
            }
            &-info {
                @extend %flex-sb-c;
            }
        }
        .team {
            width: 1.5rem;
            height: 1.5rem;
            &-info {
                column-gap: var(--one-n-half-space);
                @extend %flex-n-c;
                @extend %relative;
                &::after {
                    content: 'VS';
                    line-height: 1;
                    @extend %font-9-pr;
                    @extend %position-center;
                }
            }
            &-name {
                @extend %d-none;
            }
        }
        .swiper-button-prev,.swiper-button-next {
            @extend %d-none;
        }
    }
    .related-video-section .thumbnail-bg .img-box {
        @include border(1, c-white, 2);
        @extend %relative;
        &::after {
            content: "";
            border-radius: var(--quarter-space);
            background: linear-gradient(180.27deg, hsl(var(--hsl-c-blue-3) / 0) 15.68%, var(--c-blue-3) 99.76%) hsl(var(--hsl-c-pure-black) / .2);
            pointer-events: none;
            @include position-combo(inset);
        } 
    }
}
@media (min-width:$tablet-min-width) {
    .waf-detail {
        padding-inline: var(--full-space);

        .article {
            &-list {
                gap: 1rem;
                @include flex-config(flex, column);
                @include listing-card(rhs, 30rem);

                .article-meta {
                    flex-direction: column;
                }
            }

            &-wrap {
                display: flex;
            }

            &-thumbnail {
                @include border-radius(var(--quarter-space), hidden);
            }

            &-content {
                padding-inline: 1rem;
                margin-top: 0;
            }
        }

        .auto-play-section {
            gap: 1rem;
            display: flex;
        }

        .head {
            padding: 0;
            margin-bottom: 5.2rem;
            @include flex-config(flex, row, space-between);
        }

        .content-section {
            padding-inline: 0;
        }

        .meta {
            font-size: 1.6rem;
        }
        .social-share  {
            width: 7rem;
            .social-share-wrap {
                left: auto;
                right: 0;
            }
        }
        .video-section {
            .article-error {
                .article-title {
                    width: 70%;
                }
            }
        }
    }
    .waf-videodetail  {
        .article {
            &-detail {
                flex-direction: column;
            }
        }
        .video-section {
            .title {
                max-width: 100%;
                @include font(24,30);
            }
            .sub-title {
                @include font(18,24);
            }
            .match-info {
                left: 0;
            }
        }
        .related-video-section {
            .head {
                display: none;
            }
            .body {
                padding-inline: 0;
                height: 65rem;
                overflow-y: auto;
                @include custom-scroll;
            }
            .item-type-icon {
                transform: unset;
                @include position(var(--full-space),var(--full-space),null,unset);
            }
            .article {
                &-list {
                    flex-direction: row;
                }
                &-title {
                    display: -webkit-box;
                }
                &-content {
                    position: absolute;
                    padding-inline: var(--full-space);
                }
                &-thumbnail {
                    width: 100%;
                }
            }
            .match-number {
                @include font(18);
            }
            .team {
                width: 2.1rem;
                height: 2.1rem;
                &-info {
                    column-gap: var(--two-space);
                }
            }
        }
        &.waf-detail {
            .related-video-section {
                .article {
                    &-title, &-description {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width:$desktop-min-width) {
    .waf-detail {
        max-width: var(--container-max-width);
        margin: var(--two-space) var(--two-space) 0;
        padding-inline: 0;
        .title {
            max-width: 70%;
            font-size: 2.4rem;
        }
        .head{
            .title{
                font-size: 1.6rem;
            }
        }

        .article {
            &-detail {
                display: flex;
                gap: var(--full-space);
            }

            &-description {
                p {
                    font-size: 1.6rem;
                }
            }
        }

        .video-section {
            position: relative;
            flex-basis: 100%;
            @include border-radius(var(--half-space), hidden);
        }

        .related-video-section {
            flex-basis: 30%;
            .title {
                max-width: 100%;       
            }
            .article-title {
                display: flex;
                font-size: 1.4rem;
                width: 70%;
                height: auto;
            }
            .meta-date {
                padding-left: 0;

                &::before {
                    content: unset;
                }
            }
        }


        .body {
            overflow-y: auto;
            @include custom-scroll();
        }

        .content-section {
            position: relative;

            .article-description {
                max-width: 70%;
            }
        }

        .reaction-section {
            @include position(var(--two-space), 0);
        }

    }
    .waf-videodetail  {
        .article {
            &-detail {
                flex-direction: row;
            }
        }
        .related-video-section {
            .article {
                &-list {
                    flex-direction: column;
                }
                &-item {
                    width: 100%;
                    flex-basis: 100%;
                    flex-shrink: 1;
                }
            }
        }
    }
}