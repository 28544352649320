@use "../config/"as *;

.widget-layout-10 {
    .title {
        padding-block: 1rem;
        @extend %c-white-10;
    }
    .waf-head{
        @extend %m-b-full;
    }

    .table {
        border: 0.1rem solid hsl(var(--hsl-c-white)/0.3);
        @extend %c-white-10;
        @include border-radius(var(--half-space), hidden);

        &-wrapper-content {
            margin-block: 3rem 1rem;
            @extend %flex-column-n-c;
            @extend %w-100;
        }

        &-data {
            align-items: center;
            justify-content: center;
            &:not(:last-child){
                border-bottom: 0.1rem solid hsl(var(--hsl-c-grey)/0.2);
            }
           
            .text {
                font-family: $font-pb;
                @extend %c-blue-10;
            }
        }

        &-left {
            @extend %flex-row-c-c;

            .thead {
                @extend %d-none;
            }
            .table-row{
                &:nth-child(2){
                    border-right: 0.1rem solid hsl(var(--hsl-c-grey)/0.2);
                }
            }
        }

        &-right {
            @extend %flex-c-c;
            @extend %c-white-bg-10;

            .thead {
                order: 1;

                .round,
                .total {
                    @extend %c-blue-2-bg-10;
                }


                .round,
                .total {
                    .text {
                        @extend %c-white-10;
                        @extend %font-12-pr;
                    }
                }
                .table-data {
                    // @extend %c-blue-bg-10;
                    @extend %c-white-bg-10;
                    &.final {
                        @extend %c-blue-bg-4;
                        .text {
                            @extend %font-14-pb;
                            @extend %c-blue-10;
                        }
                    }
                    .text {
                        @extend %c-grey-10;
                        @extend %font-12-pr;
                    }
                }
            }

            .final {
                height: 4.8rem;
                @extend %c-white-bg-10;

                &.won {
                    @extend %c-orange-bg-10;

                    .text {
                        @extend %c-white-10;
                    }
                }
            }

            .final,
            .round,
            .total {
                .text {
                    font-size: 1.6rem;
                }
            }
            .round,.total{
                @extend %c-blue-bg-4;
            }


            .table-row {
                flex-direction: column;

                &:last-child {
                    order: 2;
                }
            }

            .table-data {
                height: 3.2rem;
            }

        }
    }

    .card {
        &-thumb {
            position: relative;
            @extend %flex-column-c-c;
        }

        &-title {
            @extend %flex-column-c-c;

            .text {
                @include truncate-vertical(1, 12);
                @extend %c-white-10;

            }

            .text1 {
                color: hsl(var(--hsl-c-white)/.6);
            }
        }
    }

    .thumb{
        &-img {
        width: 8.4rem;
        height: 8.4rem;
        @extend %c-blue-1-bg-10;
        @extend %ratio-1-1;
        @extend %circle-radius;
    }
    &-logo{
        width: 2.5rem;
        height: 2.5rem;
        @extend %ratio-1-1;
        @include position(null,0.5rem,0);
    }
}

    .won {
        .thumb-img {
            border: 0.4rem solid var(--c-orange);
        }
    }

    .text {
        @extend %c-white-10;
        @extend %font-12-pr;
    }
}


@media (min-width:$tablet-min-width) {
.widget-layout-10 {
    padding-inline: 0;
    margin-bottom: 0;
    padding-bottom: calc(5*var(--full-space));
    .table{
        display: flex;

        &-left{
            width: 16.5rem;
            flex-direction: column;
            justify-content: space-between;
            .thead{
                display: block;
                height: 3.8rem;
                border-bottom: 0.1rem solid hsl(var(--hsl-c-grey)/0.2);
            }
            .table-row{
                &:nth-child(2){
                    border-bottom: 0.1rem solid hsl(var(--hsl-c-grey)/0.2);
                }
            }
        }
        &-wrapper-content{
            margin: 0 var(--half-space);
            flex-direction: row;
        }
        &-right{
            width: calc(100% - 16.5rem);
            flex-direction: column;
            justify-content: flex-start;
            .table-row{
                flex-direction: row;
                height: 6.3rem;
            }
            .table-data{
                height: 100%;
            }
            .thead{
                height: 3.8rem;
                order: unset;
                .table-data {
                    @include bg(c-blue,10);
                    &.round,&.total {
                        @include bg(c-blue-2,10);
                    }
                }
            }
            .text{
                font-size: 1.4rem;
            }
            .round{
                .text{
                    font-size: 1.4rem;
                }
            }
        }
        &-data{
            &:not(:last-child){

                border-right: 0.1rem solid hsl(var(--hsl-c-blue-1)/1);
            }
            
        }
        &-row{
            height: 6.2rem;
        }
        

    }
    .card{
        &-title{
            text-align: initial;
            margin-left: var(--half-space);
            align-items: flex-start;
            text-align: left;
        }
    }
    .thumb{

        &-img{
            width: 3.4rem;
            height: 3.4rem;
        }
        &-logo{
            width: 1rem;
            height: 1rem;
            right: 0.2rem;
        }
    }
    .won{
        .thumb-img{
            border-width:0.2rem;
        }
    }
}
    
}